import React from 'react'
import Helmet from 'react-helmet'
import WithSubnavigation from './header'
import LargeWithNewsletter from './footer'

const Layout = ({ title, children }) => {
  return (
    <>
      <Helmet title={title} defer={false} />
      <WithSubnavigation />
      {children}
      <LargeWithNewsletter />
    </>
  )
}

export default Layout
