import React, { useState, useEffect } from 'react'
import {
  Box,
  chakra,
  Container,
  Link,
  SimpleGrid,
  Stack,
  Text,
  VisuallyHidden,
  Input,
  IconButton,
  useColorModeValue,
} from '@chakra-ui/react'
import { FaEnvelope, FaPhone } from 'react-icons/fa'
import logo from '../static/elitehome.png'
import { CheckIcon } from '@chakra-ui/icons'
import { sendEmail } from '../components/utils'

const SocialButton = ({ children, label, href }) => {
  return (
    <chakra.button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  )
}

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
      {children}
    </Text>
  )
}

export default function LargeWithNewsletter() {
  const [formData, setFormData] = useState({ name: '' })
  const [state, setState] = useState('idle')
  const [sent, setSent] = useState(false)

  const shouldSubmit = state === 'submitting' && !sent

  useEffect(() => {
    async function fetchData() {
      if (formData.name && !sent) {
        const res = await sendEmail(formData.name, '', 'callback')
        setSent(true)
        if (res.data.status === 'success') {
          setState('success')
        }
      }
    }

    fetchData()
  }, [shouldSubmit])

  return (
    <Box
      style={{
        position: 'relative',
        bottom: 0,
        borderTop: '1px solid #dedede',
      }}
      bg={useColorModeValue('gray.50', 'gray.900')}
      color={useColorModeValue('gray.700', 'gray.200')}
    >
      <Container as={Stack} maxW={'6xl'} py={10}>
        <SimpleGrid
          templateColumns={{ sm: '1fr 1fr', md: '2fr 1fr 1fr 2fr' }}
          spacing={8}
        >
          <Stack spacing={6}>
            <Box>
              <img style={{ width: 100, height: 100 }} src={logo} alt="logo" />
            </Box>
            <Text fontSize={'sm'}>
              © 2021 Elite Home Management. Minden jog fenntartva
            </Text>
            <Stack direction={'row'} spacing={6}>
              <SocialButton
                label={'Mail'}
                href={'mailto:g.gabor@elitehome.work'}
              >
                <FaEnvelope />
              </SocialButton>
              <SocialButton label={'Phone'} href={'tel:+36206692884'}>
                <FaPhone />
              </SocialButton>
            </Stack>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>Link</ListHeader>
            <Link href={'/'}>Főoldal</Link>
            <Link href={'/services'}>Szolgáltatásaink</Link>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>Info</ListHeader>
            <Link href={'/contact'}>Kapcsolat</Link>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>Visszahívás kérése</ListHeader>
            <Stack direction={'row'}>
              <Input
                placeholder={'Telefonszám'}
                onChange={e =>
                  setFormData({ ...formData, name: e.target.value })
                }
                bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
                value={formData.name}
                border={0}
                _focus={{
                  bg: 'whiteAlpha.300',
                }}
              />
              <IconButton
                onClick={e => {
                  e.preventDefault()
                  setState('submitting')
                }}
                disabled={!formData.name || state === 'success'}
                isLoading={state === 'submitting'}
                bg={useColorModeValue('#d63031', '#d63031')}
                color={useColorModeValue('white', 'gray.800')}
                _hover={{
                  bg: '#eb4d4b',
                }}
                aria-label="call"
                icon={state === 'success' ? <CheckIcon /> : <FaPhone />}
              />
            </Stack>
          </Stack>
        </SimpleGrid>
      </Container>
    </Box>
  )
}
