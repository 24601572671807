import axios from 'axios'

export const sendEmail = async (name, email, type) => {
  const message =
    type === 'contact'
      ? `Ajánlat kérése - ${name}, ${email}`
      : `Visszahívás kérése - ${name}`

  const subject = type === 'contact' ? `Ajánlat kérése` : `Visszahívás kérése`

  const result = await axios.post(
    'https://rapidcorp-mailer.herokuapp.com/send',
    {
      name: `${name} <g.gabor@elitehome.work>`,
      email: 'g.gabor@elitehome.work',
      message: message,
      subject: subject,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
  return result
}
